.evidence-component {
  margin-bottom: 24px;
  position: relative;

  .checkbox {
    display: inline-flex;
    margin-bottom: 0;
    position: absolute;
    right: -145px;
    top: 50%;
    transform: translateY(-50%);
  }
}