@import "src/styles/text";
@import "src/styles/colors";

.evidences {
  padding-right: 200px;

  &-chiclets {
    .skill-chiclet {
      margin-right: 8px;
    }
  }

  &-code {
    @include text-small-subtitle;
    margin-bottom: 8px;
    margin-top: 8px;
  }

  &-title {
    @include text-page-title;
    margin-bottom: 8px;
  }

  &-description {
    @include text-body;
    margin-bottom: 24px;
  }

  &-buttons {
    margin-bottom: 40px;
    position: relative;

    .btn {
      margin-right: 16px;
    }

    .checkbox {
      display: inline-flex;
      margin-bottom: 0;
      top: 10px;
      right: -168px; // 20px padding + 148px component width
      position: absolute;
    }
  }
}