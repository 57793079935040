@import "src/styles/colors";
@import "src/styles/text";

.btn {
  @include text-body;

  height: 48px;
  padding: 12px 16px;
  border-radius: 30px;
  background: $color-rich-black;
  color: $color-snow-white;
  min-width: 130px;
  outline: inherit;
  border: none;
  cursor: pointer;
  position: relative;

  &:hover {
    box-shadow: 0 4px 4px 0 rgba(33, 33, 33, 0.25);
    top: -2px;
  }

  &-secondary {
    background: $color-snow-white;
    color: $color-rich-black;
    border: 1px solid $color-rich-black;

  }

  &-tertiary {
    background: $color-gold;
    color: $color-rich-black;
    border: 1px solid $color-rich-black;
  }

  &-disabled {
    background: $color-rich-black;
    color: $color-snow-white;
    opacity: 0.5;
    cursor: not-allowed;
  }

  &-disabled:hover {
    box-shadow: none;
    top: 0;
  }
}
