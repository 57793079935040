.applicant-info {

    .applicant-profile-section {
      display: flex;
      justify-content: space-between;
    }
    .info-section {
      margin-bottom: 24px;
  
      .info-header-notes {
        display: flex;
        justify-content: space-between;
        
        h2 {
          font-size: 16px;
          font-weight: bold;
          margin: 0;
          color: black;
        }
  
        .edit-button {
          background: none;
          border: none;
          padding: 8px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #666;
          transition: color 0.2s ease;
          
          &:hover {
            color: #333;
          }
        }
      }

      .document-item-download {
        text-decoration: underline;
        cursor: pointer;
        font-family: "Avenir Next Bold", monospace;
        font-size: 17px;
        line-height: 22px;
        color: #212121;
        padding-right: 30px;
        display: flex;
        align-items: center;
        gap: 8px;

        &-disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }
      }
  
      .notes-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        margin-bottom: 10px;
      }
  
      .info-header {
        display: flex;
        align-items: center;
        gap: 12px;
        margin-bottom: 16px;
        
        svg {
          color: black;
        }
  
        h2 {
          font-size: 16px;
          font-weight: bold;
          margin: 0;
          color: black;
        }
      }
  
      .notes-content {
        textarea {
          width: calc(100% - 32px) !important;
          margin-bottom: 24px;
          height: 200px !important;
        }
  
        .button-group {
          display: flex;
          justify-content: flex-end;
          gap: 12px;
          margin-top: 16px;
        }
      }
  
      .info-content {
        background: white;
        border-radius: 8px;
  
        .single-line-item {
          
          label {
            font-size: 14px;
            font-weight: bold;
            color: black;
            min-width: 100px;
            padding-right: 4px;
          }
  
          span {
            font-size: 14px;
            color: black;
          }
  
          .download-resume-button {
            display: flex;
            align-items: center;
            gap: 8px;
            padding: 8px 16px;
            background: none;
            border: none;
            color: #4f46e5;
            cursor: pointer;
            transition: all 0.2s ease;
  
            &:hover {
              background-color: #f3f4f6;
              border-radius: 4px;
            }
  
            svg {
              color: currentColor;
            }
          }
        }
  
        > div {
          background: white;
          border: 1px solid black;
          border-radius: 8px;
          padding: 20px;
          margin-bottom: 16px;
  
          &:last-child {
            margin-bottom: 0;
          }
        }
  
        .experience-block {
          h3 {
            font-size: 16px;
            font-weight: bold;
            margin: 0 0 16px 0;
            color: black;
            border-bottom: 1px solid #E5E7EB;
            padding-bottom: 12px;
          }
  
          .currently-working {
            display: block;
            font-size: 12px;
            font-style: italic;
            color: #6B7280;
            margin: -12px 0 16px 0;
          }
  
          .detail-list {
            display: flex;
            flex-direction: column;
            gap: 8px;
          }
        }
  
        .resume-container {
          .single-line-item {
            padding: 8px 0;
          }
        }
      }

      .document-item {
        border: 1px solid black;
        border-radius: 30px;
        margin-bottom: 12px;
        overflow: hidden;
        padding: 13px 22px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
  }