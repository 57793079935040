@import "src/styles/text";
@import "src/styles/colors";

.evidence-component-table {
  table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;

    th {
      @include text-small;
      font-weight: 400;
      overflow: hidden;
      white-space: nowrap;
      padding: 8px 16px;
      border-top: 1px solid rgba($color-rich-black, 0.5);
      border-bottom: 1px solid rgba($color-rich-black, 0.5);
      border-right: 1px solid rgba($color-rich-black, 0.5);

      position: sticky;
      z-index: 1;
      top: 76px;
      background: white;
    }

    td {
      @include text-body;
      padding: 8px 16px;
      vertical-align: top;
      border-bottom: 1px solid rgba($color-rich-black, 0.5);
      border-right: 1px solid rgba($color-rich-black, 0.5);
    }

    td:nth-child(1), td:nth-child(2), td:nth-child(3) {
      text-align: center;
    }

    td:last-child {
      padding-right: 120px;
      position: relative;

      .evidence-component-table-actions {
        position: absolute;
        right: 0;
        top: 8px;

        img {
          margin-right: 16px;
          cursor: pointer;
        }

        &-disabled {
          img {
            cursor: initial;
          }
        }
      }
    }
  }

  table th:first-child,
  table td:first-child {
    border-left: 1px solid rgba($color-rich-black, 0.5);
  }

  &-title {
    border: 0 !important;
    padding: 0 !important;
    text-align: left;
    top: 0 !important;
    white-space: unset !important;
  }
}