@import "src/styles/colors";
@import "src/styles/text";

.applicant-info-documents-section {
  &-download-all {
    color: $color-rich-black;
    text-decoration: underline;
    cursor: pointer;
    @include text-small-subtitle;
    display: flex;
    align-items: flex-end;
    gap: 4px;
    span {
        margin-bottom: -2px;
    }
  }

  .info-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    .info-header {
      display: flex;
      align-items: center;
      gap: 12px;

      h2 {
        font-size: 16px;
        font-weight: bold;
        color: black;
        margin: 0;
      }
    }
  }

  .documents-list {
    .documents-header {
      display: grid;
      grid-template-columns: minmax(0, 2fr) minmax(0, 1fr) 160px;
      padding: 0px 24px;
      @include text-small;
      margin-bottom: 8px;

      span:last-child {
        text-align: center;
      }
    }

    .document-item-container {
      border: 1px solid black;
      border-radius: 30px;
      margin-bottom: 12px;
      overflow: hidden;

      &:last-child {
        margin-bottom: 0;
      }
    }

    .document-item {
      display: grid;
      grid-template-columns: minmax(0, 2fr) minmax(0, 1fr) 160px;
      padding: 12px 24px;
      align-items: center;
      background: white;
      @include text-md;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;

        .toggle-icon {
          cursor: pointer;
        }
      }

      &-download {
        color: $color-rich-black;
        text-decoration: underline;
        cursor: pointer;
        @include text-small-subtitle;
      }
    }

    .request-details {
      padding: 24px;
      background: white;
      border-top: 1px solid #E5E7EB;

      .detail-row {
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 0;
        }

        .detail-label {
          @include text-small-subtitle;
          display: block;
          margin-bottom: 4px;
        }
      }
    }
  }
}