@import "../../../styles/colors";
@import "../../../styles/text";

.edit-resume {

  &-applicant {

    &-title {
      @include text-subtitle;
      text-align: left;
      margin-bottom: 24px;
    }

    &-content {
      margin-bottom: 32px;
    }
  }

  &-description {
    @include text-body;
    margin-bottom: 24px;
  }

  &-work-title {
    @include text-subtitle;
    margin-bottom: 24px;

    &-with-delete {
      display: flex;
      justify-content: space-between;

      .edit-resume-work-subtitle {
        @include text-small-subtitle;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 24px;
      }
    }

  }

  &-education-title {
    @include text-subtitle;
    margin-top: 24px;
    margin-bottom: 24px;

    &-with-delete {
      display: flex;
      justify-content: space-between;

      .edit-resume-education-subtitle {
        @include text-small-subtitle;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 24px;
      }
    }
  }

  &-subsection-add {
    margin-top: 100px;
    margin-bottom: 56px;
    cursor: pointer;

    &-icon {
      margin-right: 10px;
    }

    &-text {
      @include text-body;
      display: inline-block;
      vertical-align: top;
      padding-top: 5px;
    }
  }

  &-subpage-navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    margin-bottom: 24px;
    align-items: center;

    &-item-left {
      justify-content: flex-start;
      display: flex;
      flex-grow: 1;
    }

    &-item-right {
      justify-content: flex-end;
      display: flex;
      flex-grow: 1;
    }

    &-work-add {
      margin-bottom: 20px;

      &-icon {
        margin-right: 10px;
        margin-left: 10px;
        cursor: pointer;
      }

      &-text {
        @include text-body;
        display: inline-block;
        vertical-align: top;
        padding-top: 5px;
        cursor: pointer;
      }
    }
  }


  &-header-delete {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &-header-mandatory {
    @include text-body-italic;
    margin-bottom: 20px;
  }

  &-review {
    margin-top: 40px;
  }
}
