.resume-progress-bar-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 50px 0;

  .resume-progress-bar-label-connector-container {
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
      margin: 0;
    }
  }

  .resume-progress-bar-connector {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 25px;

    @media (max-width: 768px) {
      height: 20px;
    }

    @media (max-width: 576px) {
      height: 15px;
    }
  }

  .round-dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    border: 10px solid black;
    position: relative;

    @media (max-width: 768px) {
      height: 8px;
      width: 8px;
      border-width: 8px;
    }

    @media (max-width: 576px) {
      height: 6px;
      width: 6px;
      border-width: 6px;
    }
  }

  .current-dot {
    width: 10px;
    height: 10px;
    background-color: grey;
    border-radius: 50%;

    @media (max-width: 768px) {
      width: 8px;
      height: 8px;
    }

    @media (max-width: 576px) {
      width: 6px;
      height: 6px;
    }
  }

  .connector {
    width: 200px;
    height: 2px;
    background-color: black;

    @media (max-width: 768px) {
      width: 100px;
    }

    @media (max-width: 576px) {
      width: 55px;
    }

    @media (max-width: 400px) {
      width: 50px;
    }
  }

  .check-icon {
    border: 5px solid black;
    border-radius: 50%;
    background-color: black;
    color: white;

    @media (max-width: 768px) {
      border-width: 4px;
      font-size: 0.9rem;
    }

    @media (max-width: 576px) {
      border-width: 3px;
      font-size: 0.8rem;
    }
  }

  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .label {
    margin-top: 5px;
    font-size: 15px;
    color: #333;
    text-align: center;
    margin-left: -200px;

    @media (max-width: 768px) {
      font-size: 13px;
      margin-left: -100px;
    }

    @media (max-width: 576px) {
      font-size: 8px;
      margin-left: -55px;
    }
  }

  .resume-progress-bar-label-connector-container:last-child .label {
    margin-left: -20px;

    @media (max-width: 768px) {
      margin-left: -10px;
    }

    @media (max-width: 576px) {
      margin-left: -15px;
    }
  }
}
