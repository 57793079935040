@import "src/styles/text";
@import "src/styles/colors";

.evidence-component-title {
  position: relative;
  @include text-subtitle;
  border-radius: 10px;
  border: 1px solid rgba($color-rich-black, 0.5);
  padding: 24px 66px 24px 16px;
  max-width: calc(100vw - 144px * 2);

  &-opened {
    border-radius: 10px 10px 0 0;
    border-bottom: 0;
  }

  &-icon {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
}